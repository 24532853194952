<template>
  <div>
    <div v-if="text && !textIsEmpty(text)" class="fk-prose">
      <!-- is empty check toevoegen -->
      <StrapiBlocks :content="computedText" :blocks="blocksComponents" />
    </div>
    <slot v-else name="empty" />
  </div>
</template>

<script lang="ts" setup>
import {
  StrapiBlocks,
  type BlocksContent,
  type BlocksComponents,
} from "vue-strapi-blocks-renderer";
import { NuxtPicture } from "#components";

const blocksComponents: Partial<BlocksComponents> = {
  image: (props) =>
    h(
      NuxtPicture,
      {
        provider: props.image.provider,
        loading: "lazy",
        src: props.image.hash,
        width: props.image.width,
        height: props.image.height,
        alt: `${props.image.alternativeText}`,
        decoding: "auto",
        class: "block",
      },
      props.children,
    ),
};

const textIsEmpty = (data: object): boolean => {
  // Base case: if data is not an array or is an empty array, return true
  if (!Array.isArray(data) || data.length === 0) {
    return true;
  }

  // Recursive case: for each item in the array
  for (const item of data) {
    // Check if the item is a TextInlineNode and has a 'text' property that is not empty
    if ("text" in item && item.text.trim() !== "") {
      return false;
    }

    // Check if the item has a 'children' property, recursively check the children
    if ("children" in item) {
      const childrenEmpty = textIsEmpty(item.children);

      // If the children are not empty, return false
      if (!childrenEmpty) {
        return false;
      }
    }
  }

  // If none of the items or their descendants had a non-empty 'text' property, return true
  return true;
};

const props = defineProps<{
  text?: BlocksContent;
}>();
const { region } = useStaticData();

const computedText = computed(() => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const rePlaceHoldersPunIntented = (text: string) => {
    return text
      .replace("%%werkgeluk_titel%%", region.value.attributes.title)
      .replace("%%werkgeluk_gemeente%%", region.value.attributes.municipality)
      .replace("%%werkgeluk_url%%", region.value.attributes.url)
      .replace("%%werkgeluk_email%%", region.value.attributes.email);
  };

  const processChildren = (children: any[]) => {
    if (!region) return children;

    return children.map((child) => {
      if (child.type === "text") {
        child.text = rePlaceHoldersPunIntented(child.text);
      } else if (child.children) {
        child.children = processChildren(child.children);
      }
      return child;
    });
  };

  if (props.text) {
    return props.text.map((item) => {
      const itemCopy = { ...item };
      itemCopy.children = processChildren(item.children);
      return itemCopy;
    }) as BlocksContent;
  }

  return props.text as unknown as BlocksContent;
});
</script>
